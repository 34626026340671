@import './helper.scss';

.software {
  @include page-padding;
  @include row;
  @include touch(flex-flow, row, column-reverse);

  &-title {
    @include row;
    color: white;
    margin-left: -10px;
    font: {
      size: 42px;
      weight: bold;
    }

    &-logo {
      height: 50px;
    }

    &-text {
      margin-left: 5px;
      height: 50px;
      line-height: 50px;
    }
  }

  &-container {
    @include col;
    @include touch(margin-top, 0, 100px);
    @include touch(width, 400px, 80%);
    align-items: start;
    margin: {
      right: 30px;
      left: 30px;
    }
  }

  &-image {
    @include touch(width, 400px, 70%);
    margin: {
      top: 20px;
      left: 30px;
      right: 30px;
    }
  }

  &-button {
    border-radius: 10px;
    background-color: rgb(40, 40, 40);
    border: 2px solid $yellow;
    width: 100px;
    height: 50px;
    color: white;
    font: {
      size: 17px;
      weight: bold;
    }
  }

  &-button:disabled {
    color: #aaaaaa;
    border: 2px solid #222222;
  }

  &-description {
    @include size(100%, 70px);
    @include touch(margin-top, -5px, 0);
    @include mobile {
      font-size: 16px;
    }
    margin-bottom: 30px;
    border-bottom: 1px solid white;
    color: white;
    text-align: start;
    line-height: 70px;
    font: {
      size: 20px;
      weight: bold;
    }
  }
}
