$header-height: 64px;
$yellow: #eecf56;

@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100..900&display=swap');
@import url('http://fonts.googleapis.com/earlyaccess/nanumgothic.css');

$lexend: "Lexend Deca", 'Nanum Barun Gothic', sans-serif;
$nanum: 'Nanum Barun Gothic', sans-serif;

@mixin mobile {
  @media screen and (max-width: 768px) {
    @content;
  }
}

@mixin tablet {
  @media screen and (max-width: 1024px) and (min-width: 769px) {
    @content;
  }
}

@mixin res($property, $desktop, $mobile) {
  #{$property}: $desktop;

  @include mobile {
    #{$property}: $mobile;
  }
}

@mixin third($property, $desktop, $tablet, $mobile) {
  #{$property}: $desktop;

  @include tablet {
    #{$property}: $tablet;
  }

  @include mobile {
    #{$property}: $mobile;
  }
}

@mixin touch($property, $desktop, $touch) {
  @include third($property, $desktop, $touch, $touch);
}

@mixin row {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
}

@mixin col {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

@mixin page-title {
  @include third(font-size, 50px, 27px, 23px);
  color: white;
  width: 100%;
  text-align: center;
  margin-bottom: 40px;
  font-weight: bold;
}

@mixin page-padding {
  padding: {
    top: 150px;
    bottom: 150px;
  }

  @include mobile {
    padding: {
      top: 100px;
      bottom: 100px;
    }
  }
}

@mixin size($w, $h) {
  width: $w;
  height: $h;
}
