@import './helper.scss';

.navbar {
  @include size(100vw, $header-height);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  transition: all 0.4s ease-in-out;
  font-family: $lexend;

  &-logo {
    width: 100px;
    margin: 0 10px;
  }

  &-menubtn {
    @include mobile {
      padding-left: 0px;
    }
  }

  &-lang {
    background: none;
    border: none;
    color: white;
    @include mobile {
      padding-right: 10px;
    }
  }

  &-buttons {
    font: {
      size: 20px;
      weight: 400;
    }
    color: white;
  }

  &-scrolled {
    background: rgba(0, 0, 0, 0.28);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
  }

  &-apply {
    @include res(width, 100px, 85px);
    @include res(font-size, 17px, 15px);
    @include res(margin-right, 15px, 5px);
    @include mobile {
      padding-left: 0;
    }

    min-width: 85px;
    color: $yellow;
    height: 40px;
    line-height: 30px;
    text-align: center;
    border: 2px solid $yellow;
    border-radius: 17px;
    font-weight: 400;
  }
}

.dropdown-content {
  background: rgb(20, 20, 20);
  border-radius: 10px;
}
