@import './helper.scss';

.modal {
  overflow: hidden;

  &-box {
    height: 600px;
    background-color: rgb(20, 20, 20);
    border-radius: 20px;
    border: 3px solid rgb(40, 40, 40);
    width: 95%;
    overflow: hidden;
  }

  &-button {
    border-radius: 10px;
    margin-top: 15px;
  }

  &-textarea {
    @include size(100%, 200px);
    resize: none;
  }
}
