@import './helper.scss';

.popup {
  &-textarea {
    @include size(100%, 200px);
    resize: none;
  }

  &-button {
    border-radius: 10px;
    margin-top: 20px;
  }
}

.modal {
  &-textarea {
    @include size(100%, 200px);
    resize: none;
  }
  overflow: hidden;
  overflow-x: hidden;
  overflow-y: hidden;

  &-title {
    margin-bottom: 40px;
  }

  &-button {
    border-radius: 10px;
    margin-top: 20px;
  }

  &-box {
    height: 600px;
    background-color: rgb(20, 20, 20);
    border-radius: 20px;
    width: 95%;
    overflow: hidden;
  }
}
