@import './helper.scss';

.home {
  @include col;
  height: calc(100vh - $header-height) !important;
  width: 100% !important;

  &-image {
    @include size(100%, 100%);
    display: block;
    object-fit: cover;
    transition: all 0.6s ease-in-out;
  }

  &-text {
    @include res(transform, translate(-50%, -75%), translate(-50%, -50%));
    @include mobile {
      white-space: pre-wrap;
    }
    font: {
      size: 45px;
      weight: 700;
      family: $lexend;
    }

    color: white;
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 5;
    width: 100%;
    opacity: 0;
    transition: opacity 2s ease-in-out;
  }

  &-scrolled {
    scale: 0.8;
    border-radius: 25px;
  }
}
