@import './helper.scss';

.perf {
  @include col;
  @include page-padding;

  background-color: rgb(30, 30, 30);

  &-sub {
    @include col;
    width: 90%;
    margin-top: 100px;
  }

  &-description {
    @include res(width, 65vw, 85%);
    @include res(font-size, 20px, 15px);
    white-space: pre-wrap;
    color: white;
    margin-top: 40px;
  }

  &-container {
    @include row;
    width: 90vw;

    margin: {
      top: 50px;
      bottom: 20px;
    }
  }

  &-image {
    @include res(width, 70vw, 90vw);
    @include res(height, 40vw, 60vw);
    border-radius: 20px;
    object-fit: cover;

    margin: {
      left: 10px;
      right: 10px;
    }

    &:focus {
      outline: none;
    }

    &-inner {
      @include res(width, 70vw, 90vw);
      @include res(height, 40vw, 60vw);
      object-fit: cover;
    }
  }

  &-warning {
    color: gray;
    margin-top: 20px;
  }
}
