@import './helper.scss';

.about {
  @include col;
  @include page-padding;

  &-buttons {
    @include row;
  }
}

.carousel {
  overflow: hidden;
  width: 70vw;
  margin-bottom: 20px;
  padding: {
    top: 50px;
    bottom: 50px;
  }

  &-item {
    @include col;
    align-items: start;
    width: 70vw;
    position: relative;
    object-fit: cover;
  }
}

.video {
  @include size(100%, 40vw);
  object-fit: cover;

  &-text {
    @include res(margin-left, 20px, 0);
    @include third(font-size, 30px, 22px, 17px);

    z-index: 1;
    font-weight: 700;
    text-align: left;

    &-up {
      @include third(margin-bottom, -60px, -50px, 0);
    }

    &-down {
      @include third(margin-top, -60px, -50px, 0);
    }
  }
}

.button {
  @include size(30px, 30px);
  border-radius: 15px;
  cursor: pointer;

  margin: {
    left: 8px;
    right: 8px;
  }

  background-color: rgb(40, 40, 40);
  &.active {
    background-color: grey;
  }
  &:hover.inactive {
    background-color: rgb(50, 50, 50);
  }

  .timer {
    width: 30px !important;
    height: 30px !important;
  }
}
