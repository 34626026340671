@tailwind base;
@tailwind components;
@tailwind utilities;
@import './components/helper.scss';

.App {
  text-align: center;
  padding-top: $header-height;
  font-family: $nanum;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}

.heading {
  color: white;
  margin-bottom: 10px;

  font: {
    size: 25px;
    weight: 700;
  }
}

.description {
  color: white;

  font: {
    weight: 400;
    size: 15px;
  }
}
