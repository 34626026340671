@import './helper.scss';

.purchase {
  @include page-padding;
  @include row;
  @include touch(flex-flow, row, column);

  &-image {
    @include touch(width, 400px, 600px);
    border-radius: 10px;
    margin: {
      left: 30px;
      right: 30px;
    }
  }

  &-container {
    @include col;
    @include touch(margin-top, -30px, 50px);
    align-items: start;
    width: 400px;
    @include touch(width, 400px, 80%);
    @include touch(margin-top, -30px, 100px);
    margin: {
      left: 30px;
      right: 30px;
    }
  }

  &-title {
    @include row;
    font-size: 30px;
    color: white;
    font-weight: bold;
    margin-left: -10px;

    &-logo {
      height: 50px;
    }

    &-text {
      margin-left: 5px;
      height: 50px;
      line-height: 50px;
      font-size: 42px;
    }
  }

  &-description {
    @include size(100%, 70px);
    @include touch(margin-top, -5px, 0);
    margin-bottom: 30px;
    border-bottom: 1px solid white;
    color: white;
    text-align: start;
    line-height: 70px;
    font: {
      size: 20px;
      weight: bold;
    }

    @include mobile {
      font-size: 16px;
    }
  }

  &-button {
    border-radius: 10px;
    background-color: rgb(40, 40, 40);
    border: 2px solid $yellow;
    width: 100px;
    height: 50px;
    color: white;
    font-weight: bold;
    font-size: 17px;
  }
}
